import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Maska from 'maska';
import App from './App.vue';
import router from './router';
import store from '@/store';
import extendJS from '@/plugins/extendJS';
import registerStorePlugins from '@/store/registerStorePlugins';
import { registerPlugins } from '@/plugins';
import { registerDirectives } from '@/directives';

import 'floating-vue/dist/style.css';
import './assets/global.scss';
import './assets/scss/base.scss';

const app = createApp(App);

registerStorePlugins(store);

// TODO: Вынести регистрацию плагинов в папку plugins (registerPlugins)
app.use(Antd)
  .use(router)
  .use(store)
  .use(Maska)
  .use(extendJS);

registerPlugins(app);
registerDirectives(app);

app.mount('#root');
