import { RouteName } from '@/enums';
import useUserStore from '@/store/user';

export const checkAccess = () => {
  const userStore = useUserStore();

  if (userStore.isStudentRole) return { name: RouteName.ERROR_403 };

  return true;
};
