import type { AxiosInstance } from 'axios';
import type { TicketPayload } from './types';

export const feedbackModule = (axios: AxiosInstance) => ({
  createOmnideskTicket(payload: TicketPayload) {
    const { contentHtml } = payload;
    return axios.post('/v1/schedule/ticket/create', {
      contentHtml,
    });
  },
});

export * as FeedbackModule from './types';
