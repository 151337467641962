import type { AxiosInstance } from 'axios';
import type {
  ScheduleChangeStudent,
  ScheduleChangeCourse,
  ScheduleChangeNewGroup,
  NewGroupsPayload,
} from './types';

export const scheduleChangeModule = (axios: AxiosInstance) => ({
  loadScheduleChangeParent() {
    return axios.get<ScheduleChangeStudent[]>('/v1/parent/children/schedule/groups/list');
  },

  loadScheduleChangeStudent() {
    return axios.get<ScheduleChangeCourse[]>('/v1/student/schedule/groups/list');
  },

  loadScheduleChangeGroupsForParent(payload: NewGroupsPayload) {
    const { contractUuid, territoryUuid } = payload;
    return axios.get<ScheduleChangeNewGroup[]>('/v1/parent/children/schedule/groups/to-change', {
      params: {
        contractUuid,
        territoryUuid,
      },
    });
  },

  loadScheduleChangeGroupsForStudent(payload: NewGroupsPayload) {
    const { contractUuid, territoryUuid } = payload;
    return axios.get<ScheduleChangeNewGroup[]>('/v1/student/schedule/groups/to-change', {
      params: {
        contractUuid,
        territoryUuid,
      },
    });
  },

  sendGroupForChangeParent(contractUuid: string, groupUuid: string) {
    return axios.post('/v1/parent/children/schedule/groups/change', {
      contractUuid,
      groupUuid,
    });
  },

  sendGroupForChangeStudent(contractUuid: string, groupUuid: string) {
    return axios.post('/v1/student/schedule/groups/change', {
      contractUuid,
      groupUuid,
    });
  },
});

export * as ScheduleChangeModule from './types';
