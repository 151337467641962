import qs from 'qs';
import axios from 'axios';

import { adminModule } from './modules/admin';
import { authModule } from './modules/auth';
import { contentModule } from './modules/content';
import { contractsAndPaymentsModule } from './modules/contractsAndPayments';
import { eventsModule } from './modules/events';
import { feedbackModule } from './modules/feedback';
import { gradeBookModule } from './modules/gradeBook';
import { profileModule } from './modules/profile';
import { progressModule } from './modules/progress';
import { schedule2035Module } from './modules/schedule2035';
import { scheduleChangeModule } from './modules/scheduleChange';
import { scheduleModule } from './modules/calendar';
import { systemModule } from './modules/system';
import router from '@/router';

import { RouteName } from '@/enums/router';
import { useAuthStore } from '@/store/auth';

const _axios = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'brackets' }),
});

_axios.interceptors.response.use(undefined, async (error) => {
  if (!axios.isAxiosError(error)) throw error;
  const resStatus = error.response?.status;

  if (resStatus == null) throw error;

  if (resStatus >= axios.HttpStatusCode.InternalServerError) {
    await router.push({ name: RouteName.ERROR_500 });

    throw error;
  }

  if (resStatus === axios.HttpStatusCode.Forbidden) {
    await router.push({ name: RouteName.ERROR_403 });

    throw error;
  }

  if (resStatus === axios.HttpStatusCode.Unauthorized) {
    const authStore = useAuthStore();

    const isNavigationSucceeded = await authStore.oauthRedirect(window.location.href);

    if (isNavigationSucceeded) throw error;

    // TODO: В идеале здесь нужно реализовать дополнительную логику, в случаи если не отработал OAuth
  }

  throw error;
});

export const api = {
  admin: adminModule(_axios),
  auth: authModule(_axios),
  content: contentModule(_axios),
  contractsAndPayments: contractsAndPaymentsModule(_axios),
  events: eventsModule(_axios),
  feedback: feedbackModule(_axios),
  gradeBook: gradeBookModule(_axios),
  profile: profileModule(_axios),
  progress: progressModule(_axios),
  schedule: scheduleModule(_axios),
  schedule2035: schedule2035Module(_axios),
  scheduleChange: scheduleChangeModule(_axios),
  system: systemModule(_axios),
};
