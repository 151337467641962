export enum PaymentTypeList {
  STEP_BY_STEP = 'StepByStep',
  ONE_PAYMENT = 'OnePayment',
  RECURENT = 'Recurent',
  BANK_INSTALLMENT_PLAN = 'BankInstallmentPlan',
  COMBO = 'Combo',
  MATERNAL_CAPITAL = 'MaternalCapital',
}

export enum PaymentStatusCode {
  OPEN = 'Open',
  CLOSED = 'Closed',
  CANCELED = 'Canceled',
  ERROR = 'Error',
  DEFERRED_PAYMENT = 'DeferredPayment',
}

export enum OperationTypeCode {
  RETURN_DS = 'ReturnDS',
  RETURN_TRANSFER_DS = 'ReturnTransferDS',
  RECEIVING_DS = 'ReceivingDS',
  RECEIVING_TRANSFER_DS = 'ReceivingTransferDS',
}

export enum ContractAgreementType {
  MANAGER = 'Manager',
  AUTOSALES = 'Autosales',
  UNIVERSITY_2035 = 'University2035',
}

export enum ConclusionStage {
  TRANSFERRED_TO_CLIENT = 'TransferredToClient',
  SIGNED = 'Signed',
  ARCHIVED = 'Archived',
  FREE = 'Free',
}

export enum ContractPaymentType {
  ADVANCE_PAYMENT = 'AdvancePayment',
  FIRST_PAYMENT = 'FirstPayment',
  NEXT_PAYMENT = 'NextPayment',
}

export enum ContractFeatureCompany {
  UMAX = 'Umax',
  ACADEMY = 'Academy',
}

export enum ContractsStatusCode {
  OPEN = 'Open',
  CONCLUDED = 'Concluded',
  FROZEN = 'Frozen',
  ON_TERMINATION = 'OnTermination',
  TERMINATION = 'Termination',
  NOT_PAID = 'NotPaid',
}
